import React, { useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { img1, servPlat1, servPlat2, servPlat3, servPlat4 } from "../../assets/img";
import PresetListTabcomp from "./Preset/PresetListTabcomp";

const ProductCarousel = ({ showPopupHander, eventList, containerRef }) => {
  const detailRefs = useRef({}); // Store refs for each item

  const products = [
    {
      id: 1,
      img: servPlat3,
      tit: '1dInsert Cards by Moo - 5" x 7',
      start: 1.56,
    },
    {
      id: 2,
      img: servPlat2,
      tit: 's1dInsert Cards by Moo - 5" x 7s',
      start: 0.61,
    },
    {
      id: 3,
      img: servPlat1,
      tit: 'Insert Cards by Moo - 5" x 7',
      start: 1.56,
    },
    {
      id: 4,
      img: img1,
      tit: 'Cards by Moo - 5" x 7s',
      start: 0.61,
    },
    {
      id: 5,
      img: servPlat4,
      tit: 'Cards by Moo - 5" x 7s',
      start: 0.61,
    },
    {
      id: 6,
      img: img1,
      tit: 'Cards by Moo - 5" x 7s',
      start: 0.61,
    },
    {
      id: 7,
      img: servPlat4,
      tit: 'Cards by Moo - 5" x 7s',
      start: 0.61,
    },
    {
      id: 8,
      img: servPlat4,
      tit: 'Cards by Moo - 5" x 7s',
      start: 0.61,
    },
  ];

  const carouselOptions = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
      1400: { items: 8 },
    },
  };
  const carouselProducts = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
      1400: { items: 8 },
    },
  };

  const handleClick = (id) => {
    const element = detailRefs.current[id];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Owl Carousel */}
      <OwlCarousel className="owl-theme" {...carouselOptions}>
        {products.map((product) => (
          <div
            key={product.id}
            className="item"
            onClick={() => handleClick(product.id)}
            style={{
              cursor: "pointer",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <img
              src={product.img}
              alt={product.tit}
              style={{ width: "100%", borderRadius: "8px" }}
            />
            <h4 className="f4 fs-15 fs-lg-16 text-black mt-2 mb-2">{product.tit}</h4>
          </div>
        ))}
      </OwlCarousel>
      {/* <PresetListTabcomp
        type="product"
        onCLickhandler={showPopupHander}
        eventList={eventList}
        containerRef={containerRef}
      /> */}
      <div style={{ marginTop: "20px" }}>
        {products.map((product) => (
          <div
            key={product.id}
            ref={(el) => (detailRefs.current[product.id] = el)}
            style={{
              marginBottom: "20px",
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              background: "#f9f9f9",
            }}
          >
            <h4 className="f4 fs-12 fs-sm-16 fs-md-18 fs-xl-20 fs-xxl-22">Product Details</h4>
            <OwlCarousel className=" mt-3" {...carouselProducts}>
              <div  style={{padding:'10px'}}>
                <img
                  src={product.img}
                  alt={product.tit}
                  style={{ width: "100%", marginBottom: "10px", borderRadius: "8px" }}
                />
                <h4 className="f2 fs-12 fs-sm-14 fs-md-15 fs-xl-16">{product.tit}</h4>
                {/* <p style={{ color: "#555" }}>Starting at ${product.start.toFixed(2)}</p> */}
              </div><div style={{padding:'10px'}}>
                <img
                  src={product.img}
                  alt={product.tit}
                  style={{ width: "100%", marginBottom: "10px", borderRadius: "8px" }}
                />
                <h4 className="f2 fs-12 fs-sm-14 fs-md-15 fs-xl-16">{product.tit}</h4>
                {/* <p style={{ color: "#555" }}>Starting at ${product.start.toFixed(2)}</p> */}
              </div><div style={{padding:'10px'}}>
                <img
                  src={product.img}
                  alt={product.tit}
                  style={{ width: "100%", marginBottom: "10px", borderRadius: "8px" }}
                />
                <h4 className="f2 fs-12 fs-sm-14 fs-md-15 fs-xl-16">{product.tit}</h4>
                {/* <p style={{ color: "#555" }}>Starting at ${product.start.toFixed(2)}</p> */}
              </div><div style={{padding:'10px'}}>
                <img
                  src={product.img}
                  alt={product.tit}
                  style={{ width: "100%", marginBottom: "10px", borderRadius: "8px" }}
                />
                <h4 className="f2 fs-12 fs-sm-14 fs-md-15 fs-xl-16">{product.tit}</h4>
                {/* <p style={{ color: "#555" }}>Starting at ${product.start.toFixed(2)}</p> */}
              </div>
            </OwlCarousel>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductCarousel;
