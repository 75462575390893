import React, { useState, useEffect, useReducer } from "react";
import ListIcon from "@mui/icons-material/List";
// import { listIcon, SpecialCateg } from "../../../assets/img";
// import { categList } from "../../../json/staticJson";
import { useDispatch } from "react-redux";
import { saveLeftCategIndex } from "../redux/slice/leftCategIndex";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import {
  Searcher,
  product1,
  ticker,
  listIcon,
  SpecialCateg,
} from "../assets/img";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useSelector } from "react-redux";
import { categList } from "../json/staticJson";

// import RangeSet from "../../Range/RangeSet";
import { ArrowDropDown } from "@mui/icons-material";
import RangeSet from "../component/Range/RangeSet";
import { useColorListMutation, useLazyTagsfilterQuery } from "../redux/api/api";
import DoneIcon from "@mui/icons-material/Done";
import CachedIcon from "@mui/icons-material/Cached";

const BulkProductFullScreen = ({
  tick,
  categoryList,
  showPopupHander,
  addProductHandler,
  setViewId,
  onClickProduct,
  getAddToCart,
  getProductView,
  cartData,
  deleteCart,
  userCartID,
  product,
  values,
  setValues,
  setNormalId,
  SetPriceSort,
  priceSort,
  max,
}) => {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [colorId, setColorId] = useState("");
  const [normal, setNormal] = useState([]);
  const [event, setEvent] = useState([]);
  const [icon, setIcon] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [cartList, setCartList] = useState();
  const [color, setColor] = useState(false);
  const [search, setSearch] = useState("");
  const [drag, setDrag] = useState(false);
  const [normalid, setnormalid] = useState();
  const [eventid, setEventid] = useState();
  const [iconid, setIconid] = useState();
  const [activeSection, setActiveSection] = useState();

  const catInd = useSelector((state) => state?.leftCategIndex);
  const cartDatas = useSelector((state) => state.cartLists?.arrayOfObjects);
  const HandledDrag = () => {
    setDrag(!drag);
  };
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const HandledSearch = (e) => {
    const SearchTerm = e.target.value;
    setSearch(SearchTerm);
  };

  const ColorDropdown = () => {
    setColor(!color);
  };
  //RTK

  const [tagsListApi] = useLazyTagsfilterQuery();
  const [colorListApi] = useColorListMutation();

  const getTagsList = () => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        setNormal(res?.normalTag);
        setEvent(res?.eventTag);
        setIcon(res?.iconTag);
        // console.log(res,"res")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getColorsList = () => {
    colorListApi()
      .unwrap()
      .then((res) => {
        setColorList(res?.colors);
        // console.log(res,"res")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTagsList();
    getColorsList();
  }, []);

  //   const addProduct = (e) => {
  //     const found = cartData.some((em) => em.id === e.id);
  //     if (!found) {
  //       dispatch(saveCartLists(e));
  //     } else {
  //       dispatch(removeCartLists(e.id));
  //     }
  //   };

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, []);

  // console.log(cartData);
  // console.log(catInd);

  const [categShow, setCategShow] = useState(false);
  const categShowHandler = () => {
    setCategShow(!categShow);
  };
  const handleColorClick = (id) => {
    setColorId(id);
    setColor(!color);
  };
  const tags = () => {
    let temp = [];
    if (normalid) {
      temp.push(normalid);
    }
    if (iconid) {
      temp.push(iconid);
    }
    if (eventid) {
      temp.push(eventid);
    }
    //  }else if(eventid){
    //  }else{
    //  }
    setNormalId(temp);
  };
  useEffect(() => {
    tags();
  }, [normalid, eventid, iconid]);

  const lowpriceHandel = () => {
    SetPriceSort("low");
    setShowFilter(!showFilter);
  };
  const highpriceHandel = () => {
    SetPriceSort("high");
    setShowFilter(!showFilter);
  };
  const refreshHandel = () => {
    SetPriceSort("low");
    setSearch("");
    setColorId("");
    setnormalid("");
    setEventid("");
    setIconid("");
    setValues([5, 1000]);
    product("", "", []);
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.scrollY + 100;

      sections.forEach((section) => {
        if (
          section.offsetTop <= scrollPosition &&
          section.offsetTop + section.offsetHeight > scrollPosition
        ) {
          setActiveSection(section.getAttribute("id"));
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    // setSidebarOpen(false);
  };

  return (
    <>
      <div
        className={`left-one ${
          categShow ? "left-product-show" : "left-product-hid"
        } left-product`}
      >
        <button onClick={categShowHandler} className="cat-title cust-btn d-flex d-lg-none">
      <ListIcon />
      {categShow && <p>Categories</p>}
    </button>
    <button onClick={categShowHandler} className="cat-title cust-btn d-none d-lg-flex justify-content-lg-center">
      <div className="list-box d-flex align-items-center ">
        <ListIcon />
        { <p style={{ width: "135px" }}>Categories</p>}
      </div>
    </button>

        <div className="d-flex align-items-center justify-content-lg-center">
          <div className="">
            {categoryList.map((section, index) => {
              return (
                <div className="" key={section?.id}>
                  <li
                    className={`list-box d-flex align-items-center menu-a-list ${
                      +activeSection === section?.id ? "activelist" : ""
                    }`}
                    href={`#${
                      section?.products?.length > 0
                        ? section?.id
                        : "/products/bulk-pack"
                    }`}
                    onClick={(e) => {
                      setCategShow(false);
                      if (section?.products?.length > 0) {
                        e.preventDefault();
                        scrollToSection(section?.id);
                      }
                    }}
                  >
                    <div className="me-2 border-0 img-back  d-flex ac-jc my-1">
                      <img src={section?.icon_url} alt="" />
                    </div>
                    <a
                      // className={`mb-0 ms-3 f3 menu-a-list ${+activeSection === section?.id ? "activelist" : ""
                      //   }`}
                      style={{ width: "135px" }}
                      // href={`#${section?.products?.length > 0
                      //     ? section?.id
                      //     : "/products/bulk-pack"
                      //   }`}
                      // onClick={(e) => {
                      //   setCategShow(false);
                      //   if (section?.products?.length > 0) {
                      //     e.preventDefault();
                      //     scrollToSection(section?.id);
                      //   }
                      // }}
                    >
                      {section?.name}
                    </a>
                  </li>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="withoutcart rit-prod bulk-prod mt-0 ms-lg-2">
        <h4 className="sect-title mb-1">Bulk Merch</h4>
        <p className="f4 fs-17 fs-lg-22 blue mb-2">
          Sometimes, 1 piece of merch is “the one” :)
        </p>
        <p className="parag disc w-80" style={{ lineHeight: "30px" }}>
          Merchandise can be ethereal (okay, maybe just in our minds) - but
          honestly, it works. It makes people feel like they are in it together
          through thick and thin.
        </p>
       <div className="d-flex gap-4 mt-4 w-90 flex-wrap ms-0 justify-content-between">
          <div className="filter-menu-build">
            <select
              className="btn btn-light w-100 shadow-box1 px-3 text-start f3 m-0 fs-lg-18"
              value={iconid}
              onChange={(e) => setIconid(e.target.value)}
            >
              {/* <p className="f3 m-0 fs-lg-18">Event Tags</p> */}
              <option value={""} className="f3 m-0 fs-lg-18">
                <p> Shop By</p>
              </option>
              {icon?.map((item, ind) => (
                <option className="f3 m-0 fs-lg-18" value={item?.id}>
                  <p>{item?.name}</p>
                </option>
              ))}
            </select>
          </div>
          <div className="filter-menu-build">
            <select
              className="btn btn-light w-100 shadow-box1 text-start px-3 f3 m-0 fs-lg-18"
              value={eventid}
              onChange={(e) => {
                setEventid(e.target.value);
              }}
            >
              {/* <p className="f3 m-0 fs-lg-18">Event Tags</p> */}
              <option value={""} className="f3 m-0 fs-lg-18">
                <p> Event</p>
              </option>
              {event?.map((item, ind) => (
                <option className="f3 m-0 fs-lg-18" value={item?.id}>
                  <p>{item?.name}</p>
                </option>
              ))}
            </select>
          </div>
          {/* </div> */}
          <div className="filter-menu-build">
            <select
              className="btn btn-light w-100 shadow-box1 px-3 text-start f3 m-0 fs-lg-18"
              value={normalid}
              onChange={(e) => {
                setnormalid(e.target.value);
              }}
            >
              {/* <p className="f3 m-0 fs-lg-18">Event Tags</p> */}
              <option value={""} className="f3 m-0 fs-lg-18">
                <p>Our Collections</p>
              </option>
              {normal?.map((item, ind) => (
                <option className="f3 m-0 fs-lg-18" value={item?.id}>
                  <p>{item?.name}</p>
                </option>
              ))}
            </select>
          </div>
          <div className="filter-menu-build">
            <button
              className="btn btn-light w-100 shadow-box1 px-3 "
              onClick={HandledDrag}
            >
              <p style={{height:"23px"}} className="f2 m-0 fs-lg-18">Budget</p>
            </button>
            <div className="mt-2">
              {drag && (
                <RangeSet
                  setValues={setValues}
                  values={values}
                  product={product}
                  search={search}
                  colorId={colorId}
                  max={max}
                />
              )}
            </div>
          </div>
          <div className="filter-menu-build">
            <button
              className="btn btn-light w-100 shadow-box1 px-3"
              onClick={ColorDropdown}
            >
              <p style={{height:"22px"}} className="f2 m-0 fs-lg-18">Colour</p>
            </button>
            {color && (
              <div className="popup d-flex flex-column">
                <h6>Select Colour</h6>
                <div className="d-flex flex-wrap gap-1">
                  {colorList?.map((item, ind) => {
                    return (
                      <button
                        style={{ background: `${item?.hex_code}` }}
                        className="img"
                        onClick={() => {
                          handleColorClick(item?.id);
                        }}
                        key={ind}
                      >
                        {colorId == item?.id && (
                          <DoneIcon className="text-white fs-25" />
                        )}
                        {/* <img src={ticker} alt="" className="img" /> */}
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="d-flex gap-4 mt-0 mb-3 justify-content-between  w-100 flex-wrap  m-auto">
            <div className="d-flex flex-wrap gap-2">
              <div className="border-5 position-relative ps-0">
                <img src={Searcher} alt="" className="imgs" />
                <input
                  type="text"
                  className="inputcontainer  border-0"
                  value={search}
                  onChange={(event) => {
                    product("", event.target.value, values);
                    // setSearch(event.target.value);
                  }}
                  placeholder="Search by tags/Products"
                />
              </div>
              <button
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-2 rounded"
                onClick={() => refreshHandel()}
              >
                <CachedIcon />
              </button>
            </div>
            <div className="d-flex ac-je position-relative mt-2">
              <p className="filters mb-0 f3">Sort By :</p>
              <div className="d-flex ac-jc">
                <p className="filtertxt mb-0">Price</p>
                <ArrowDropDown onClick={toggleFilter} />
              </div>

              {showFilter && (
                <div className="filter-options position-absolute ">
                 
                    <p
                      onClick={() => {
                        lowpriceHandel();
                      }}
                      className={`f1 ${priceSort == "low" ? "active" : ""}`}
                    >
                     Price: Low to High
                    </p>
                    <p
                      onClick={() => {
                        highpriceHandel();
                      }}
                      className={`f1 mb-0 ${priceSort == "high" ? "active" : ""}`}
                    >
                     Price: High to Low
                    </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="d-flex align-items-center justify-content-end position-relative">
          <FilterAltIcon
            onClick={toggleFilter}
            className="fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 pack-title"
          />
           <p className="filters mb-0 f3">Sort By :</p>
          {showFilter && (
            <div className="filter-options position-absolute ">
              <p>Price</p>
              <div>
                <p onClick={toggleFilter} className="active">
                  Low to High
                </p>
                <p onClick={toggleFilter}>High to Low</p>
              </div>
            </div>
          )}
        </div> */}

        {categoryList?.map((item, ind) => {
          return (
            item?.products?.length > 0 && (
              <section id={item?.id} key={ind}>
                <h5 className="pack-title mt-2 mb-0 mb-lg-3 ms-2">{item?.title}</h5>
                <p className="parag disc w-90 ms-2">{item?.description}</p>

                <div className="d-flex flex-wrap w-100 ac-je">
                  <div className="d-flex flex-wrap ps-0 w-100 ac-js">
                    {item?.products?.map((product, inx) => {
                      return (
                        <div
                          className="p-2 pe-md-2 pe-xxl-2 py-md-2 py-xxl-2 prod-list-box-tl"
                          key={inx}
                        >
                          <div className="prod-list-box">
                            <button
                              // onClick=>{showPopupHander,setViewId(product?.id)}
                              onClick={() => {
                                // showPopupHander();
                                getProductView(product?.id);
                              }}
                              className="cust-btn prod-list-box-img"
                            >
                              <img
                                src={
                                  product?.defaultImage
                                    ? product?.defaultImage
                                    : product1
                                }
                                alt=""
                              />
                            </button>
                            <div className="btm-prod">
                              <h3>{product?.name}</h3>
                              <div className="d-flex justify-content-between">
                                <p className="f2 mb-0">
                                  Starting at {+product?.for_25}
                                </p>
                                <button
                                  onClick={() => {
                                    // addProductHandler(item, ind, product, inx);
                                    if (cartData?.[product?.id]?.cart_id) {
                                      deleteCart(
                                        cartData?.[product?.id]?.cart_id,
                                        product?.id
                                      );
                                    } else {
                                      onClickProduct(product?.id);
                                    }

                                    // getAddToCart(product?.id, product?.for_100);
                                  }}
                                  className="plus"
                                >
                                  {tick?.includes(product?.id) ? (
                                    <CheckIcon />
                                  ) : (
                                    <AddIcon />
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            )
          );
        })}
      </div>
    </>
  );
};

export default BulkProductFullScreen;
