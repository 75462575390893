// @ts-nocheck
import React from "react";
import { Route, Routes } from "react-router-dom";

import "../src/assets/sass/default/style.scss";
import "../src/assets/sass/default/responsive.scss";
import "../src/assets/sass/constatnt/custome.scss";
import "../src/assets/sass/constatnt/width.scss";

import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import SignupScreen from "./routes/SignupScreen";
import HomeScreen from "./routes/HomeScreen";
import Layout from "./Layout/Layout";
import LoginScreen from "./routes/LoginScreen";
import BuildProductScreen from "./routes/BuildProductScreen";
import PresetProductScreen from "./routes/PresetProductScreen";
import BulkProductScreen from "./routes/BulkProductScreen";
import EcommerceProductScreen from "./routes/EcommerceProductScreen";
import MockupRequest from "./routes/MockupRequest";
import BulkMockupRequest from "./routes/BulkMockupRequest";
import AboutUsScreen from "./routes/AboutUsScreen";
import BlogScreen from "./routes/BlogScreen";
import FaqScreen from "./routes/FaqScreen";
import Membership from "./routes/Membership";
import PricingScreen from "./routes/PricingScreen";
import RedeemScreen from "./routes/RedeemScreen";
import ForgetPassword from "./routes/ForgetPassword";
import PresetPackProductScreen from "./routes/PresetPackProductScreen";
import PresetMockupRequest from "./routes/PresetMockupRequest";
import LetsStart from "./routes/LetsStart";
import DashboardScreen from "./routes/DashboardScreen";
import MyProducts from "./routes/MyProducts";
import MyProductDetails from "./routes/MyProductDetails";
import OrderScreen from "./routes/OrderScreen";
import ShippmentSuccess from "./routes/ShippmentSuccess";
import ShippmentScreen from "./routes/ShippmentScreen";
import MyInventory from "./routes/MyInventory";
import MyInventoryDetail from "./routes/MyInventoryDetail";
import SelectProductScreen from "./routes/SelectProductScreen";
import ProfileScreen from "./routes/ProfileScreen";
import CompanyScreen from "./routes/CompanyScreen";
import RedeemProduct from "./routes/RedeemProduct";
import AccountMembership from "./routes/AccountMembership";
import TransactionScreen from "./routes/TransactionScreen";
import RedeemScreenTwo from "./routes/RedeemScreenTwo";
import AutoshipForm from "./component/Redeem/AutoshipForm";
import IntegrationScreen from "./routes/IntegrationScreen";
import BookConsult from "./routes/BookConsult";
import SustainablityScreen from "./routes/SustainabilityScreen";
import InventoryOutStack from "./component/InventoryDetail/InventoryOutStack";
import ReorderSelectProduct from "./routes/ReorderSelectProduct";
import InventoryInStock from "./component/InventoryDetail/InventoryInStock";
import FormOne from "./component/Redeem/RedeemForm/FormOne";
import FormTwo from "./component/Redeem/RedeemForm/FormTwo";
import SelectTheem from "./component/Redeem/SelectTheem";
import ProofDetailScreen from "./routes/ProofDetailScreen";
import ShipmentHomeScreen from "./routes/ShipmentHomeScreen";
import LandingPageDes from "./component/Redeem/LandingPageDes";
import ProductSelectionForm from "./component/Redeem/ProductSelectionForm";
import EditConfirmation from "./component/Redeem/EditConfirmation";
import ShipmentSettings from "./component/Redeem/ShipmentSettings";
import AutoshipNameScreen from "./component/Redeem/AutoshipNameScreen";
import RedeemUserAccount from "./component/Redeem/RedeemUserAccount";
import MyAccountsScreen from "./routes/MyAccountsScreen";
import InvoicesScreen from "./routes/InvoicesScreen";
import TemplateScreen from "./routes/TemplateScreen";
import StartProjectScreen from "./component/templateTwo/Screen/StartProjectScreen";
import OurProjectScreen from "./component/templateTwo/Screen/OurProjectScreen";
import ProjectSubmit from "./component/templateTwo/Screen/ProjectSubmit";
import LayoutTwo from "./component/templateTwo/Components/Layout/LayoutTwo";
import DashboardScreenTwo from "./component/templateTwo/Screen/DashboardScreenTwo";
import ServicesScreen from "./routes/ServicesScreen";
import BranchScreen from "./routes/BranchScreen";
import TellProject from "./component/Product/EcommerceMock/TellProject";
import ContactDetails from "./component/Product/EcommerceMock/ContactDetails";
import GiveBack from "./routes/GiveBack";
import BlogTip from "./routes/BlogTip";
import BlogSwag from "./routes/BlogSwag";
import ResetPassword from "./routes/ResetPassword";
import PrivateRoutes from "./redux/store/privateRoutes";
import PresetRequest from "./routes/PresetRequest";
import MyProjectProduct from "./routes/MyProjectProduct";
import PresentationListA from "./routes/PresentationListA";
import EstimationPageA from "./routes/EstimationPageA";
import PresentationGalleryPage3A from "./routes/PresentationGalleryPage3A";
import PresentationGalleryPage2A from "./routes/PresentationGalleryPage2A";
import PayMethod from "./routes/PayMethod";
import ProductsLibrary from "./routes/ProductsLibrary";

const ReactRoutes = () => {
  const routeName = "ninos";
  const routeNametwo = "tendrncy";

  return (
    <Routes>
      {/* <Route path="login" element={<LoginScreen />} /> */}
      <Route path="/method" element={<PayMethod />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path={routeName} element={<TemplateScreen />} />
      <Route path="/presentation-list-a" element={<PresentationListA />} />
      <Route path="/estimation-page-a" element={<EstimationPageA />} />
      <Route
        path="/presentation-gallery-page-3a"
        element={<PresentationGalleryPage3A />}
      />
      <Route
        path="/presentation-gallery-page-2a"
        element={<PresentationGalleryPage2A />}
      />
      <Route element={<Layout />}>
        <Route path="/signup" element={<SignupScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/" index element={<HomeScreen />} />
        <Route path="/products/build-pack" element={<BuildProductScreen />} />
        <Route
          path="/dashboard/lets-start/build-pack"
          element={<BuildProductScreen />}
        />{" "}
        <Route
          path="/dashboard/products/e-commerce"
          element={<EcommerceProductScreen />}
        />{" "}
        <Route path="/ecommerce-mock" element={<TellProject />} />
        <Route path="/ecommerce-delivery" element={<ContactDetails />} />
        <Route path="/products/preset-pack" element={<PresetProductScreen />} />
        <Route path="/products/bulk-pack" element={<BulkProductScreen />} />
        <Route
          path="/products/e-commerce"
          element={<EcommerceProductScreen />}
        />
        <Route path="/mock-request" element={<MockupRequest />} />
        <Route path="/bulk-request" element={<BulkMockupRequest />} />
        <Route path="/preset-request" element={<PresetMockupRequest />} />
        <Route path="/preset-requests" element={<PresetRequest />} />
        <Route path="/shipment" element={<ShippmentSuccess />} />
        <Route path="/company/about-us" element={<AboutUsScreen />} />
        <Route path="/company/give-back" element={<GiveBack />} />
        <Route path="/company/blog" element={<BlogScreen />} />
        <Route path="/blog-tip" element={<BlogTip />} />
        <Route path="/blog-swag" element={<BlogSwag />} />
        <Route path="/company/faq" element={<FaqScreen />} />
        <Route path="/platform/membership" element={<Membership />} />
        <Route path="/platform/services" element={<ServicesScreen />} />
        <Route path="/company/blog/membership" element={<Membership />} />
        <Route path="/platform/redeem" element={<RedeemScreen />} />
        <Route path="/pricing" element={<PricingScreen />} />
        <Route path="/account/branches" element={<BranchScreen />} />
        <Route path="/redeem" element={<RedeemScreenTwo />} />
        <Route path="/redeem/dashboard" element={<AutoshipNameScreen />} />
        <Route path="/account/company" element={<CompanyScreen />} />
        <Route path="/account/membership" element={<AccountMembership />} />
        <Route path="/billing/transactions" element={<TransactionScreen />} />
        <Route path="/buildproduct" element={<BuildProductScreen />} />
        <Route path="/preset-pack" element={<PresetProductScreen />} />
        <Route path="/autoship" element={<AutoshipForm />} />
        <Route path="/shipment-home" element={<ShipmentHomeScreen />} />
        <Route path="/autoship-name" element={<AutoshipNameScreen />} />
        <Route path="/redeem-user-account" element={<RedeemUserAccount />} />
        <Route path="/billing/accounts" element={<MyAccountsScreen />} />
        <Route path="/billing/invoices" element={<InvoicesScreen />} />
        <Route
          path="/products/preset-pack/preset-pack-list"
          element={<PresetPackProductScreen />}
        />
        <Route
          path="/pricing/preset-pack-list"
          element={<PresetPackProductScreen />}
        />
        <Route path="/lets-start" element={<LetsStart />} />
        <Route path="/dashboard/lets-start" element={<LetsStart />} />
        <Route path="/my-inventory" element={<MyInventory />}>
          <Route
            path="/my-inventory/out-of-stock"
            element={<InventoryOutStack />}
          />
          <Route
            path="/my-inventory/in-of-stock"
            element={<InventoryInStock />}
          />
        </Route>
        <Route path="/reorder-select" element={<ReorderSelectProduct />} />
        <Route
          path="/my-inventory/my-inventory-detail"
          element={<MyInventoryDetail />}
        />
          <Route path="/company/sustainability" element={<SustainablityScreen />} />
        {/* after-login */}
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/my-products" element={<MyProducts />} />
          <Route path="/products-library" element={<ProductsLibrary />} />
          <Route path="/my-product-detail" element={<MyProductDetails />} />
          <Route
            path="/my-product-View_Proof_detail"
            element={<MyProjectProduct />}
          />
          <Route path="/my-order" element={<OrderScreen />} />
          <Route path="/my-shipment" element={<ShippmentScreen />} />
          <Route path="/select-product" element={<SelectProductScreen />} />
          <Route path="/redeem-product" element={<RedeemProduct />} />
          <Route path="/integrations" element={<IntegrationScreen />} />
          <Route path="/book-consult" element={<BookConsult />} />
          <Route path="/account/profile" element={<ProfileScreen />} />
          <Route
            path="/services/membership/book-consult"
            element={<BookConsult />}
          />
          {/* <Route path="q" element={<SustainablityScreen />} /> */}
          <Route path="/proof-details" element={<ProofDetailScreen />} />
        </Route>
      </Route>
      <Route element={<LayoutTwo />}>
        <Route path="/start-project" element={<StartProjectScreen />} />
        <Route path="/our-project" element={<OurProjectScreen />} />
        <Route path="/submit-project" element={<ProjectSubmit />} />
        <Route path={routeNametwo} element={<DashboardScreenTwo />} />
      </Route>
    </Routes>
  );
};

export default ReactRoutes;
