import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDashboardOrderListMutation } from "../../../redux/api/api";
import moment from "moment/moment";

const LeftBannerCom = () => {
  const navigate = useNavigate();
  const [list, setlist] = useState([]);

  //RTK
  const [orderListApi] = useDashboardOrderListMutation();

  const bannerOderList = () => {
    orderListApi()
      .then((res) => {
        let temp = [];

        res?.data?.orders?.map((item, ind) => {
          let project_request_details = [];

          item?.project_request_details?.map((pro, indx) => {
            let obj = {
              ...pro,
            };

            if (pro?.product_sku_id?.id) {
              let finder = pro?.product?.colorImages?.find(
                (i) => i?.color_id == pro?.product_sku_id?.color_id
              );

              if (finder?.id) {
                obj.selected_image_url = finder?.image_url;
              } else {
                obj.selected_image_url = pro?.product?.defaultImage;
              }
            } else {
              obj.selected_image_url = pro?.product?.defaultImage;
            }

            project_request_details.push(obj);
          });

          temp.push({
            ...item,
            project_request_details: project_request_details,
          });
        });

        setlist(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    bannerOderList();
  }, []);
  // Show only the top 5 items, excluding items with status == 3
  const visibleData = list.filter((item) => item.status !== 3).slice(0, 5);

  console.log("list:", list);
  console.log("visibleData:", visibleData);

  return (
    <div className="leftBannerCom w-100 w-md-70 bg-lt-blue p-4 rounded-5">
      <h4 className="sect-title mb-3">View Mockup</h4>
      <div style={{ overflowX: "auto" }}>
        <table
          className="rounded-5"
          style={{
            borderCollapse: "collapse",
            width: "100%",
            border: "1px solid black",
            background: "#fff",
          }}
        >
          <thead>
            <tr>
              <th className="tableHeaderStyle">S.No</th>
              <th className="tableHeaderStyle">Image</th>
              <th className="tableHeaderStyle">Request Date</th>
              <th className="tableHeaderStyle">Quantity</th>
              <th className="tableHeaderStyle">Status</th>
              <th className="tableHeaderStyle">View Proof</th>
            </tr>
          </thead>
          <tbody>
            {visibleData.map((item, index) => (
              <tr className="py-2" key={index}>
                <td className="tableCellStyle">{index + 1}</td>
                <td className="tableCellStyle">
                  <img
                    src={item?.project_request_details[0]?.selected_image_url}
                    alt="item"
                  />
                </td>
                <td className="tableCellStyle">
                  {moment(item?.when_needed).format("DD-MMM-yyy")}
                </td>
                <td className="tableCellStyle">
                  {item?.project_request_details[0]?.qty}
                </td>
                <td className="tableCellStyle">
                  <p
                    className={`mb-0 py-2 rounded-4 fs-13 fs-md-16 ${
                      item.status == 1
                        ? "design bg-lt-orange"
                        : item.status == 2
                        ? "ready bg-lt-blue1"
                        : "approved"
                    }`}
                  >
                    {item.status == 1
                      ? "Creating Mockup"
                      : item.status == 2
                      ? "Mockup Ready"
                      : "Approved"}
                  </p>
                </td>
                <td className="tableCellStyle">
                  <button
                    onClick={() => {
                      if (item?.project !== null) {
                        if (item?.project.status === 5) {
                          navigate("/my-order");
                        } else {
                          navigate("/my-product-View_Proof_detail", {
                            state: item,
                          });
                        }
                      } else {
                        navigate("/my-product-detail", { state: item });
                      }
                    }}
                    className="get-start-view m-auto border-0"
                  >
                    {item?.project !== null ? "View Proof" : "View"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeftBannerCom;
